import React, { useEffect } from "react";

import softwareVector from "../../Files/svg/software-vector.svg";
import "../../styles/serivces/software.css";
import HelmetSEO from "../helmet";

const SoftwareDevelopmentDetail = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="background-color">
      <HelmetSEO/>
      <div className="service-detail-container">
        <header className="service-header">
          {/* <img
            src={softwareLogo}
            alt="Software Development"
            className="service-logo"
          /> */}

          <h1>Understanding Software Development</h1>
          <img
            src={softwareVector}
            alt="Vector Description"
            className="vector-icon"
          />
        </header>

        <section className="service-description">
          <p>
            Software development is the disciplined process of conceptualizing,
            designing, programming, testing, and deploying software
            applications. It involves a collaborative partnership between
            clients and development teams to create solutions that meet specific
            business objectives.
          </p>
        </section>


        <section className="service-description">
          <p>
            Software development is the disciplined process of conceptualizing,
            designing, programming, testing, and deploying software
            applications. It involves a collaborative partnership between
            clients and development teams to create solutions that meet specific
            business objectives.
          </p>
        </section>


        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Requirements Analysis</h3>
              <p className="timeline-description">
                Clearly defining project goals, scope, and functionalities
                through detailed collaboration with the client.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Design and Architecture</h3>
              <p className="timeline-description">
                Creating a blueprint for the software, outlining its structure,
                user interface, and database components.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Development and Coding</h3>
              <p className="timeline-description">
                Transforming the design into functional software using
                programming languages and development frameworks.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Testing and Quality Assurance</h3>
              <p className="timeline-description">
                Rigorously evaluating the software to identify and rectify
                defects, ensuring it aligns with specified requirements.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Deployment</h3>
              <p className="timeline-description">
                Distributing and installing the software in the target
                environment for end-user access.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Maintenance and Support</h3>
              <p className="timeline-description">
                Providing ongoing updates, enhancements, and troubleshooting to
                optimize software performance.
              </p>
            </div>
          </div>
        </div>

        <section className="technologies">
          <h2>Technologies Used</h2>
          <div className="technologies-group">
            <h3>Programming Languages:</h3>
            <ul>
              <li>C#</li>
              <li>Python</li>
              <li>Java</li>
              <li>JavaScript</li>
              <li>TypeScript</li>
              <li>PHP</li>
            </ul>
          </div>
          <div className="technologies-group">
            <h3>Databases:</h3>
            <ul>
              <li>MS SQL Server</li>
              <li>MySQL</li>
              <li>PostgreSQL</li>
              <li>Oracle</li>
            </ul>
          </div>
          <div className="technologies-group">
            <h3>User Interface (UI):</h3>
            <ul>
              <li>Angular</li>
              <li>React JS</li>
              <li>Blazor.NET</li>
            </ul>
          </div>
          <div className="technologies-group">
            <h3>Mobile Apps:</h3>
            <ul>
              <li>Flutter</li>
              <li>React Native</li>
              <li>MAUI.NET</li>
            </ul>
          </div>
          <div className="technologies-group">
            <h3>Cloud Platforms:</h3>
            <ul>
              <li>Azure</li>
              <li>AWS</li>
              <li>GCP</li>
            </ul>
          </div>
          <div className="technologies-group">
            <h3>DevOps:</h3>
            <ul>
              <li>Azure DevOps</li>
              <li>Terraform</li>
              <li>Docker</li>
              <li>Kubernetes</li>
            </ul>
          </div>
        </section>

        <section className="benefits">
          <h2>The Benefits of Custom Software</h2>
          <ul>
            <li>
              <strong> Tailored Solutions: </strong> Addressing unique business
              needs and processes.
            </li>
            <li>
              <strong> Enhanced Efficiency:</strong>
              Optimizing workflows and resource utilization.
            </li>
            <li>
              <strong> Scalability:</strong> Adapting to evolving business
              requirements.
            </li>
            <li>
              <strong> Competitive Advantage: </strong>
              Distinguishing the business from competitors through innovative
              solutions.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentDetail;
