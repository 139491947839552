import React from "react";
import { Helmet } from "react-helmet-async";
import previewImage from "../Files/images/BLOOMWIZ LOGO - White.png";

const HelmetSEO = ({ title, description, image }) => {
  const pageTitle = title || "BloomWiz Software Private Limited";
  const pageDescription =
    description ||
    "Embark on a transformative journey with us as we craft cutting-edge software solutions that propel your business to unparalleled heights of success.";
  const pageImage = image || previewImage;
  return (
    <Helmet>
      <meta name="Description" content={pageDescription} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={pageImage} />
    </Helmet>
  );
};

export default HelmetSEO;
