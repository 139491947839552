import React from 'react'
import "../styles/philosophy.css"
import HelmetSEO from './helmet';

 const Philosophy = () => {
    return (
        <section className="philosophy-section">
          <HelmetSEO/>
          <div className="overlay"></div>
          <div className="shapes">
            <div className="shape-1"></div>
            <div className="shape-2"></div>
            <div className="shape-3"></div>
          </div>
          <div className="container">
            <div className="content">
              <h2>Our Philosophy</h2>
              <div className="accent-line"></div>
              <h3>
                Innovation, Expertise, and Transformation: The Cornerstones of Our
                Success
              </h3>
              <p>
              At the core of our ethos lies the unwavering belief that software,
            when harnessed effectively, holds the key to unlocking new frontiers
            of growth, innovation, and success. We approach every project with a
            deep understanding of the transformative potential that software
            possesses. We meticulously design and develop solutions that not
            only address immediate challenges but also empower businesses to
            adapt, evolve, and thrive in the ever-changing digital world.
              </p>
            </div>
          </div>
        </section>
      );
    };
export default Philosophy
