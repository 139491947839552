import React from "react";
import "../styles/case-study.css";
import image1 from "../Files/images/case-study-1.jpg";
import image2 from "../Files/images/case-study-2.jpg";
import image3 from "../Files/images/case-study-3.jpg";
import image4 from "../Files/images/case-study-4.jpg";
import { Link } from "react-router-dom";
import HelmetSEO from "./helmet";

const CaseStudies = () => {
  return (
    <section id="case-studies">
<HelmetSEO/>

    <section  className="case-study-section">
      <div>
        <h1>Case Studies</h1>
        <h2>
          Witnessing the Transformative Power of Technology: A Glimpse into Our
          Success Stories
        </h2>
      </div>
      <div className="case-container">
        <div className="case-card">
          <img src={image1} alt="Case Study 1" />
          <div className="case-content">
            <h3>
              Streamline operations, boost productivity, and achieve operational
              excellence through automation and process optimization
            </h3>
  
            <Link to="/case-studies/case-study-1"  className="case-read-more">
            Read More
            </Link>
          </div>
        </div>
        <div className="case-card">
          <img src={image2} alt="Case Study 2" />
          <div className="case-content">
            <h3>
              Expand their reach, enhance customer experience, and drive
              business growth through innovative and user-centric mobile
              applications.
            </h3>
            <Link to="/case-studies/case-study-2"  className="case-read-more">
            Read More
            </Link>
          </div>
        </div>
        <div className="case-card">
          <img src={image3} alt="Case Study 3" />
          <div className="case-content">
            <h3>
              Gain a competitive edge through innovation and technological
              advancements, empowering them to stay ahead of the curve and adapt
              to market shifts
            </h3>
            <Link to="/case-studies/case-study-3"  className="case-read-more">
            Read More
            </Link>
          </div>
        </div>
        <div className="case-card">
          <img src={image4} alt="Case Study 3" />
          <div className="case-content">
            <h3>
              Reduce costs, optimize resource utilization, and achieve financial
              efficiency through cloud-based solutions.
            </h3>
            <Link to="/case-studies/case-study-4"  className="case-read-more">
            Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
    </section>
  );
};

export default CaseStudies;
