import React, { useEffect } from "react";
import "../../styles/case-studies/case-study.css";
import caseStudyImage from "../../Files/images/case-study-3.jpg";
import HelmetSEO from "../helmet";


const CaseStudyDetailC = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="case-study-page">
      <HelmetSEO
        title={"BloomWiz Software Private Limited"}
        description="Embark on a transformative journey with us as we craft cutting-edge
		software solutions that propel your business to unparalleled heights of
		success."
        image={caseStudyImage}
      />
      <header className="case-study-header-3">
        <div className="case-title-section">
          <h2>Driving Innovation for Competitive Advantage</h2>
        </div>
      </header>

      <section className="content-section">
        <div className="content-container">
          <div className="title-desc">
            <h3>
              In today's rapidly evolving business landscape, staying ahead of
              the curve requires a relentless pursuit of innovation. Bloomwiz
              empowers organizations to unlock their full potential by
              leveraging cutting-edge technologies and strategic solutions.
            </h3>
          </div>
          <section className="additional-content">
            <h3>A Culture of Innovation</h3>
            <p>
              At the core of Bloomwiz's success is a deep-rooted culture of
              innovation. We foster a collaborative environment where creativity
              thrives, and new ideas are nurtured. Our team of experts is
              passionate about exploring emerging technologies and identifying
              opportunities to apply them to real-world challenges.
            </p>
          </section>
          <section className="additional-content">
            <h3>Strategic Technology Partnerships</h3>
            <p>
              We believe that strategic partnerships are essential for driving
              innovation. Bloomwiz collaborates with leading technology
              providers to access the latest advancements and deliver
              cutting-edge solutions to our clients. By staying ahead of
              industry trends, we ensure that our clients benefit from the most
              innovative technologies available.
            </p>
          </section>
          <section className="additional-content">
            <h3>Customized Solutions for Competitive Edge</h3>
            <p>
              Bloomwiz takes a tailored approach to innovation, working closely
              with clients to understand their unique business goals and
              challenges. We develop customized solutions that address specific
              pain points and create new opportunities for growth. Our focus on
              delivering measurable results empowers clients to gain a
              competitive advantage and outperform their rivals.
            </p>
          </section>

          <section className="additional-content">
            <h3>Conclusion</h3>
            <p>
              Bloomwiz is committed to helping organizations harness the power
              of innovation to drive growth and success. By combining our
              expertise, strategic partnerships, and client-centric approach, we
              deliver transformative solutions that propel businesses forward.
            </p>
            <p>
            Would you like to delve deeper into a specific case study or discuss a particular industry?
            </p>
          </section>
        </div>
      </section>
    </div>
  );
};

export default CaseStudyDetailC;
