import React from "react";
import "../styles/about.css";
import aboutVector from "../Files/svg/about-us.svg";
import HelmetSEO from "./helmet";
const About = () => {
  return (
    <section className="about" id="about">
      <HelmetSEO/>
      <div className="about-content">
        <div className="about-image">
          <img src={aboutVector} alt="Team Collaboration" />
        </div>
        <div className="about-text">
          <h1>About Us</h1>
          <h2>Your Trusted Partner in Software Development Excellence</h2>
          <p>
          In the ever-evolving landscape of software development, we stand as
            a beacon of expertise, a team of passionate and experienced
            professionals dedicated to crafting tailored software solutions that
            empower businesses to thrive in the dynamic digital realm. Our
            unwavering commitment to understanding our clients' unique needs and
            aspirations has propelled us to achieve an exceptional track record
            of success across diverse industries. We seamlessly blend technical
            mastery with a profound grasp of business objectives, ensuring that
            every software solution we deliver seamlessly aligns with your
            strategic goals.
          </p>
        </div>
      </div>
      <div className="background-circle circle-3"></div>
      <div className="background-circle circle-4"></div>
    </section>
  );
};
export default About;
