import React, { useEffect } from "react";
import CloudDevelopmentVector from "../../Files/svg/cloud-service-vector.svg";
import "../../styles/serivces/cloud-service.css";

import HelmetSEO from "../helmet";

const CloudDevelopmentDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="background-color">
      <HelmetSEO />
      <div className="service-detail-container">
        <header className="service-header">
          <h1>Accelerate Your Business with Bloomwiz Cloud Solutions</h1>
          <img
            src={CloudDevelopmentVector}
            alt="Vector Description"
            className="vector-icon"
          />
        </header>

        <section className="service-description">
          <p>
            Bloomwiz offers robust cloud computing solutions designed to
            optimize your business operations and drive growth. By leveraging
            the power of Azure, AWS, and GCP, we deliver tailored strategies
            that align with your unique business objectives.
          </p>
        </section>

        <section className="framework-selection">
          <h2>Transform Your Business with the Cloud</h2>
          <p>Cloud computing offers a multitude of benefits, including:</p>
          <div className="framework-list">
            <div className="framework-item">
              <h4>Enhanced Scalability</h4>
              <p>
                Dynamically adjust IT resources to meet fluctuating demands,
                optimizing costs and performance.
              </p>
            </div>
            <div className="framework-item">
              <h4>Increased Agility</h4>
              <p>
                Accelerate time-to-market with rapid application deployment and
                infrastructure provisioning.
              </p>
            </div>
            <div className="framework-item">
              <h4>Improved Cost Efficiency</h4>
              <p>
                Optimize IT spending through pay-per-use models and resource
                optimization.
              </p>
            </div>
            <div className="framework-item">
              <h4>Robust Security</h4>
              <p>
                Benefit from advanced security measures provided by leading
                cloud platforms.
              </p>
            </div>
          </div>
        </section>

        <div className="timeline">
          <div>
            <h2>Comprehensive Cloud Services from Bloomwiz</h2>
            <p>Our comprehensive cloud services encompass:</p>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">
                Infrastructure as a Service (IaaS):
              </h3>
              <p className="timeline-description">
                Build and manage virtualized IT resources for maximum
                flexibility and control.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Platform as a Service (PaaS):</h3>
              <p className="timeline-description">
                Develop and deploy applications without the complexities of
                infrastructure management.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Software as a Service (SaaS)</h3>
              <p className="timeline-description">
                Access pre-built applications delivered over the internet for
                immediate productivity gains.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Cloud Migration</h3>
              <p className="timeline-description">
                Seamlessly transition your existing IT infrastructure to the
                cloud with minimal disruption.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Cloud Optimization</h3>
              <p className="timeline-description">
                Analyze your cloud environment to identify cost-saving
                opportunities and performance improvements.
              </p>
            </div>
          </div>
        </div>

        <section className="additional-content">
          <h3>Scalable Cloud Solutions for Your Business Growth</h3>
          <p>
            Bloomwiz understands that your business needs evolve. Our cloud
            solutions are designed to adapt to your changing requirements. By
            starting with a scalable foundation and strategically growing your
            cloud infrastructure, we ensure optimal performance and
            cost-efficiency.
          </p>
        </section>

        <section className="benefits">
          <h2>Partner with Bloomwiz for Cloud Success</h2>
          <ul>
            <li>
              <strong> Strategic Cloud Consulting: </strong> Develop a
              comprehensive cloud strategy aligned with your business goals.
            </li>
            <li>
              <strong>Custom Cloud Solutions:</strong>
              Design and implement cloud solutions to address your unique
              challenges.
            </li>
            <li>
              <strong> Cost Optimization:</strong> Maximize your return on
              investment through cloud cost management strategies.
            </li>
            <li>
              <strong>Robust Security and Compliance: </strong>
              Protect your sensitive data with industry-leading security
              practices.
            </li>
            <li>
              <strong>Dedicated Support: </strong>
              Receive ongoing support from our cloud experts.
            </li>
          </ul>
        </section>

        <section className="additional-content">
          <p>
            Let Bloomwiz be your trusted partner in your cloud journey. Contact
            us today to explore how our cloud solutions can drive your business
            forward.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CloudDevelopmentDetail;
