import React from "react";
import "../styles/hero.css";
import heroVector from "../Files/svg/hero-vector.svg";
import jsLogo from "../Files/svg/icons8-javascript.svg";
import pythonLogo from "../Files/svg/icons8-python.svg";
import htmlLogo from "../Files/svg/icons8-html-64.svg";
import cssLogo from "../Files/svg/icons8-css.svg";
import cLogo from "../Files/svg/icons8-c.svg"
import { useLocation, useNavigate } from "react-router-dom";


const HeroSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  const handleNavClick = (section) => {
    if (!isHomePage) {
      navigate("/");
      setTimeout(() => {
        document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    } else {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className="hero" id="home">
      <div className="hero-left">
        <h1>BLOOMWIZ</h1>
        <h2>
          Unleashing the Power of Technology:
          <br />
          Your Gateway to Digital Transformation
        </h2>
        <p>
          Embark on a transformative journey with us as we craft cutting-edge
          software solutions that propel your business to unparalleled heights
          of success.
        </p>
        <button className="cta-button"  onClick={() => handleNavClick("about")}>Get Started</button>
      </div>
      <div className="hero-right">
        <img src={heroVector} alt="Web Development Vector" />
      </div>
      <div>
        {" "}
        <img
          src={jsLogo}
          alt="JavaScript Logo"
          className="floating-logo logo-js"
        />
        <img
          src={pythonLogo}
          alt="Python Logo"
          className="floating-logo logo-python"
        />
        <img
          src={htmlLogo}
          alt="HTML Logo"
          className="floating-logo logo-html"
        />
        <img src={cssLogo} alt="CSS Logo" className="floating-logo logo-css" />
        <img src={cLogo} alt="CSS Logo" className="floating-logo logo-c" />
      </div>
      <div className="background-circle circle-1"></div>
      <div className="background-circle circle-2"></div>
    </section>
  );
};
export default HeroSection;
