import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/footer.css";
import footerLogo from "../Files/images/logoWhite.png";
import HelmetSEO from "./helmet";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/";

  const handleNavClick = (section) => {
    if (!isHomePage) {
      navigate("/");
      setTimeout(() => {
        document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer id="footer" className="wrapper">
      <HelmetSEO/>
      <div className="inner">
        <section>
          <div className="footer">
            <div className="content">
              <div className="footer1">

                <div className="image fit">
                  <img src={footerLogo} alt="BloomWiz logo" />
                </div>

                <div className="row links">
                  <ul>
                    <li>
                      {isHomePage ? (
                        <ScrollLink to="about" smooth={true} duration={500}>
                          About us
                        </ScrollLink>
                      ) : (
                        <a onClick={() => handleNavClick("about")}>About us</a>
                      )}
                    </li>
                    <li>
                      <a href="mailto:hr@saan.in">Contact us</a>
                    </li>
                    <li>
                      {isHomePage ? (
                        <ScrollLink to="services" smooth={true} duration={500}>
                          Our Services
                        </ScrollLink>
                      ) : (
                        <a onClick={() => handleNavClick("services")}>
                          Our Services
                        </a>
                      )}
                    </li>
                    <li>
                      <a href="#">Blogs</a>
                    </li>
                    <li>
                      {isHomePage ? (
                        <ScrollLink to="case-studies" smooth={true} duration={500}>
                          Case Studies
                        </ScrollLink>
                      ) : (
                        <a onClick={() => handleNavClick("case-studies")}>
                          Case Studies
                        </a>
                      )}
                    </li>
                    <li>
                      {isHomePage ? (
                        <ScrollLink to="testimonials" smooth={true} duration={500}>
                          Testimonials
                        </ScrollLink>
                      ) : (
                        <a onClick={() => handleNavClick("testimonials")}>
                          Testimonials
                        </a>
                      )}
                    </li>
                  </ul>
                </div>

                <div className="row social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/people/Bloomwiz/61564024060712/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bloomwiz.software/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@bloomwizsoftware"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/bloomwizsw"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-square-x-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/bloomwiz-software"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="row address">
                  Regd. Office: 19B, Sri Garden, Vilankurichi, Coimbatore, TN,
                  India - 641035. Ph: (+91)7550177757
                </div>

                <div className="row copyright">
                  Copyright © Bloomwiz Software Private Limited - All rights
                  reserved
                </div>
              </div>
            </div>
          </div>
          <div className="credits">
            Images:
            <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
              Unsplash
            </a>
          
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
