import React, { useEffect } from "react";
import DevopsDevlopmentVector from "../../Files/svg/devops-vector.svg";
import "../../styles/serivces/devops.css";
import HelmetSEO from "../helmet";

const DevopsDevlopmentDetail = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="background-color">
      <HelmetSEO/>
      <div className="service-detail-container">
        <header className="service-header">
          <h1>Accelerating Your Business with Bloomwiz DevOps</h1>
          <img
            src={DevopsDevlopmentVector}
            alt="Vector Description"
            className="vector-icon"
          />
        </header>

        <section className="service-description">
          <p>
            DevOps is a collaborative approach that integrates development and
            operations teams to streamline software delivery. At Bloomwiz, we
            employ DevOps principles to enhance efficiency, quality, and speed
            to market.
          </p>
        </section>

        <section className="framework-selection">
          <h2>Key Benefits of DevOps</h2>
          <p>By adopting DevOps practices, organizations can:</p>
          <div className="framework-list">
            <div className="framework-item">
              <h4>Increase Velocity:</h4>
              <p>
                Rapidly deliver high-quality software through automated
                processes.
              </p>
            </div>
            <div className="framework-item">
              <h4>Improve Collaboration</h4>
              <p>
                Foster a culture of shared responsibility and accountability.
              </p>
            </div>
            <div className="framework-item">
              <h4>Enhance Reliability</h4>
              <p>
                Reduce deployment failures and downtime through continuous
                testing.
              </p>
            </div>
            <div className="framework-item">
              <h4>Optimize Resource Utilization</h4>
              <p>Efficiently allocate infrastructure and personnel.</p>
            </div>
          </div>
        </section>

        <div className="timeline">
          <div>
            <h2>Core DevOps Services</h2>
            <p>
              Bloomwiz offers a range of services to support your DevOps
              journey:
            </p>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">DevOps Consulting</h3>
              <p className="timeline-description">
                Assess your current practices and develop a tailored roadmap.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">CI/CD Pipeline Implementation</h3>
              <p className="timeline-description">
                Automate build, test, and deployment processes.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Infrastructure as Code (IaC)</h3>
              <p className="timeline-description">
                Define and manage infrastructure using code for consistency and
                efficiency.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">
                Containerization and Orchestration
              </h3>
              <p className="timeline-description">
                Package and deploy applications using Docker and Kubernetes.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">
                Cloud Migration and Optimization
              </h3>
              <p className="timeline-description">
                Leverage cloud platforms for scalability and cost-effectiveness.
              </p>
            </div>
          </div>
        </div>

        <section className="benefits">
          <h2>Bloomwiz' DevOps Expertise</h2>
          <ul>
            <li>
              <strong> Azure DevOps: </strong> A unified platform for planning,
              developing, testing, and deploying applications.
            </li>
            <li>
              <strong>Terraform:</strong>
              An infrastructure-as-code tool for managing and provisioning cloud
              resources.
            </li>
            <li>
              <strong> Docker:</strong> A containerization platform for
              packaging applications and dependencies.
            </li>
            <li>
              <strong>Kubernetes: </strong>A container orchestration system for
              managing and scaling containerized workloads.
            </li>
          </ul>
        </section>

        <section className="additional-content">
          <h3>Scalability and Flexibility</h3>
          <p>
            Our DevOps approach emphasizes scalability and flexibility. We begin
            with a solid foundation and incrementally expand your DevOps
            capabilities based on your evolving needs. By utilizing cloud-based
            infrastructure and automation, we ensure optimal resource
            utilization and cost efficiency.
          </p>
        </section>
        <section className="additional-content">
          <h3>Partner with Bloomwiz</h3>
          <p>
            Bloomwiz is committed to helping you achieve DevOps excellence. Our
            team of experienced professionals will work closely with you to
            understand your business objectives and deliver tailored solutions.
          </p>
          <p>
          Contact us today to learn how we can accelerate your software delivery.
          </p>
        </section>

       
      </div>
    </div>
  );
};

export default DevopsDevlopmentDetail;
