import React, { useState, useEffect } from "react";
import "../styles/testimonial.css";
import HelmetSEO from "./helmet";

const Testimonials = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [autoDirection, setAutoDirection] = useState(1);

  const slides = [
    {
      quote:
        "I love you the more in that I believe you had liked me for my own sake and for nothing else.",
      author: "John Keats",
    },
    {
      quote:
        "But man is not made for defeat. A man can be destroyed but not defeated.",
      author: "Ernest Hemingway",
    },
    {
      quote:
        "In the end, we will remember not the words of our enemies, but the silence of our friends.",
      author: "Martin Luther King Jr.",
    },
    {
      quote: "That which does not kill us makes us stronger.",
      author: "Friedrich Nietzsche",
    },
  ];

  const shapes = ["shape-circle", "shape-square", "shape-triangle"];

  const getRandomShape = () => {
    return shapes[Math.floor(Math.random() * shapes.length)];
  };

  const showSlides = (index) => {
    let newIndex = index;
    if (index >= slides.length) newIndex = 0;
    if (index < 0) newIndex = slides.length - 2;
    setSlideIndex(newIndex);
  };

  const plusSlides = (n) => {
    setIsUserInteracted(true);
    showSlides(slideIndex + n * 2);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isUserInteracted) {
        showSlides(slideIndex + autoDirection * 2);
        setAutoDirection((prevDirection) => (prevDirection === 1 ? -1 : 1));
      }
    }, 2500);

    return () => clearInterval(interval);
  }, [slideIndex, isUserInteracted, autoDirection]);

  return (
    <section id="testimonials">
      <HelmetSEO/>
      <div className="testimonial-section">
        <div className="testimonial-header">
          <h2>Our Clients' Unwavering Endorsement of Our Expertise</h2>
        </div>
        <div className="slideshow-container">
          <div className="slide-row">
            {slides.slice(slideIndex, slideIndex + 2).map((slide, index) => (
              <div key={index} className="mySlides">
                <q>{slide.quote}</q>
                <p className="author">- {slide.author}</p>
                {/* Random Shape */}
                <div className={getRandomShape()}></div>
              </div>
            ))}
          </div>

          <a className="prev" onClick={() => plusSlides(-1)}>
            ❮
          </a>
          <a className="next" onClick={() => plusSlides(1)}>
            ❯
          </a>
        </div>

        <div className="dot-container">
          {Array.from({ length: Math.ceil(slides.length / 2) }).map(
            (_, index) => (
              <span
                key={index}
                className={`dot ${slideIndex === index * 2 ? "active" : ""}`}
                onClick={() => {
                  setIsUserInteracted(true);
                  setSlideIndex(index * 2);
                }}
              ></span>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
