import React from "react";
import "../styles/service.css";
import softwareLogo from "../Files/svg/software.svg";
import webLogo from "../Files/svg/web-development.svg";
import mobileLogo from "../Files/svg/mobile-app.svg";
import cloudLogo from "../Files/svg/cloud.svg";
import devopsLogo from "../Files/svg/devops.svg";
import { Link } from "react-router-dom";
import HelmetSEO from "./helmet";


const OurServices = () => {
  return (
    <section id="services">
      <HelmetSEO/>
      <div class="container">
        <div class="header">
          <h1>Our Services</h1>
          <h3>
            Unleashing the Power of Technology to Realize Your Business
            Objectives
          </h3>
        </div>

        <div class="services">
          <div class="service">
            <div class="icon custom-software">
              <img src={softwareLogo} alt="Software Development" />
            </div>
            <h2>Software Development</h2>
            <p>
              Tailored solutions that seamlessly integrate with your systems and
              align with your business goals.
            </p>
            <Link to="/services/software" className="arrow-button">
              →
            </Link>
          </div>
          <div class="service">
            <div class="icon mobile-app">
              <img src={mobileLogo} alt="mobile Development" />
            </div>
            <h2>Mobile App Development</h2>
            <p>
              Engaging, user-friendly apps that expand your reach and drive
              growth.
            </p>
            <Link to="/services/mobile-app" className="arrow-button">
              →
            </Link>
          </div>
          <div class="service">
            <div class="icon web-development">
              <img src={webLogo} alt="web Development" />
            </div>
            <h2>Web Development</h2>
            <p>
              Visually stunning websites that enhance your online presence and
              success.
            </p>
            <Link to="/services/web-app" className="arrow-button">
              →
            </Link>
          </div>
          <div class="service">
            <div class="icon cloud-computing">
              <img src={cloudLogo} alt="cloud Development" />
            </div>
            <h2>Cloud Computing Solutions</h2>
            <p>
              Cloud technology for enhanced scalability, flexibility, and
              cost-efficiency.
            </p>
            <Link to="/services/cloud-service" className="arrow-button">
              →
            </Link>
          </div>
          <div class="service">
            <div class="icon devops">
              <img src={devopsLogo} alt="devops Development" />
            </div>
            <h2>DevOps and IT Infrastructure</h2>
            <p>
              Seamless integration and optimization for smooth operations and
              enhanced security.
            </p>
            <Link to="/services/devops" className="arrow-button">
              →
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
