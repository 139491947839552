import React, { useEffect } from "react";
import WebDevelopmentVector from "../../Files/svg/webdevelopment-vector.svg";
import "../../styles/serivces/web-app.css";
import HelmetSEO from "../helmet";

const WebDevelopmentDetail = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="background-color">
      <HelmetSEO/>
      <div className="service-detail-container">
        <header className="service-header">
          <h1>Web Development Solutions at Bloomwiz</h1>
          <img
            src={WebDevelopmentVector}
            alt="Vector Description"
            className="vector-icon"
          />
        </header>

        <section className="service-description">
          <p>
            In today's competitive online landscape, a robust and engaging web
            presence is no longer a luxury; it's a business imperative. At
            Bloomwiz, a leading custom software development company, we empower
            businesses to cultivate their digital presence by crafting
            exceptional web applications and experiences. Our team of skilled
            developers leverages a comprehensive suite of cutting-edge
            technologies, including ASP.NET, Blazor.NET, ReactJS, and Angular,
            to deliver web solutions that are tailored to your unique business
            objectives.
          </p>
        </section>

        <section className="framework-selection">
          <h2>Comprehensive Web Development Services</h2>
          <p>
            We offer a wide range of web development services designed to
            enhance your online presence and drive business growth:
          </p>
          <div className="framework-list">
            <div className="framework-item">
              <h4>Custom Web Application Development</h4>
              <p>
                We specialize in developing custom web applications that are
                scalable, secure, and performance-driven, ensuring a seamless
                user experience across all devices and platforms.
              </p>
            </div>
            <div className="framework-item">
              <h4>E-commerce Solutions</h4>
              <p>
                Our team builds robust e-commerce platforms that provide a
                smooth and secure shopping experience, complete with custom
                features like payment gateways, inventory management, and
                personalized user experiences.
              </p>
            </div>
            <div className="framework-item">
              <h4>Responsive Web Design</h4>
              <p>
                We create responsive web designs that adapt to any screen size,
                ensuring your website looks and functions perfectly on desktops,
                tablets, and mobile devices.
              </p>
            </div>
            <div className="framework-item">
              <h4>Content Management Systems (CMS)</h4>
              <p>
                We offer CMS solutions like WordPress, Joomla, and Drupal,
                allowing you to manage your website content with ease and
                flexibility.
              </p>
            </div>
          </div>
        </section>

        <div className="timeline">
          <div>
            <h2>Our Development Process: Quality from Start to Finish</h2>
            <p>
              At Bloomwiz, we follow a structured and efficient development
              process to ensure the highest quality in every project:
            </p>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Discovery and Planning:</h3>
              <p className="timeline-description">
                We begin with a thorough discovery phase, understanding your
                business goals, target audience, and the specific
                functionalities required. This allows us to create a detailed
                project plan that guides the development process.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Design and Development:</h3>
              <p className="timeline-description">
                Our designers and developers work collaboratively to create
                visually stunning and highly functional web applications. We
                prioritize user experience, ensuring that every aspect of the
                design is intuitive and engaging.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Testing and Quality Assurance:</h3>
              <p className="timeline-description">
                Rigorous testing is conducted at every stage of development to
                identify and resolve any issues. This ensures that the final
                product is not only functional but also secure and reliable.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Deployment and Maintenance:</h3>
              <p className="timeline-description">
                Once the application is ready, we handle the deployment process,
                ensuring a smooth launch. We also offer ongoing maintenance and
                support to keep your application updated and running smoothly.
              </p>
            </div>
          </div>
        </div>

        <section className="framework-selection">
          <h2>The Power of a Strong Web Presence</h2>
          <div className="framework-list">
            <div className="framework-item">
              <h4>Enhanced User Engagement</h4>
              <p>
                A well-designed website captivates users, encouraging them to
                stay longer, explore more, and engage with your content.
              </p>
            </div>
            <div className="framework-item">
              <h4>Increased Conversion Rates</h4>
              <p>
                Optimized web applications lead to higher conversion rates,
                turning visitors into loyal customers.
              </p>
            </div>
            <div className="framework-item">
              <h4>Scalability and Growth</h4>
              <p>
                Our web solutions are designed to grow with your business,
                providing the scalability needed to support your expanding
                operations.
              </p>
            </div>
            <div className="framework-item">
              <h4>SEO Optimization</h4>
              <p>
                We implement SEO best practices to ensure your website ranks
                well in search engine results, driving more traffic and
                visibility to your business.
              </p>
            </div>
          </div>
        </section>

        <section className="additional-content">
          <h3>Your Partner in Web Development</h3>
          <p>
            When you partner with Bloomwiz for your web development needs,
            you're not just getting a service provider—you're gaining a
            strategic partner dedicated to your success. Our team is committed
            to delivering solutions that exceed your expectations and drive your
            business forward.
          </p>
          <p>
            Contact us today to discuss how we can help you elevate your web
            presence and achieve your business goals!
          </p>
        </section>
      </div>
    </div>
  );
};

export default WebDevelopmentDetail;
