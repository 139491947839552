import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/nav-bar.css";
import logo from "../Files/images/WhiteLogo.png";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isHomePage = location.pathname === "/";

  const handleNavClick = (section) => {
    if (!isHomePage) {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(section)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }
    toggleMenu();
  };

  return (
    <header className="navbar">
      <div className="logo" onClick={() => handleNavClick("home")}>
        <img src={logo} alt="Bloomwiz Logo" />
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={menuOpen ? "menu open" : "menu"}>
        <li className="close-menu" onClick={toggleMenu}>
          &times;
        </li>

        {isHomePage ? (
          <>
            <li>
              <ScrollLink
                to="hero"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="services"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Services
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="about"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="footer"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Contact
              </ScrollLink>
            </li>
          </>
        ) : (
          <>
            <li onClick={() => handleNavClick("/")}>Home</li>
            <li onClick={() => handleNavClick("services")}>Services</li>
            <li onClick={() => handleNavClick("about")}>About</li>
            <li onClick={() => handleNavClick("footer")}>Contact</li>
          </>
        )}
      </ul>
    </header>
  );
};

export default NavBar;
