import React, { useEffect } from "react";
import "../../styles/case-studies/case-study.css";
import caseStudyImage from "../../Files/images/case-study-2.jpg";
import HelmetSEO from "../helmet";

const CaseStudyDetailB = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="case-study-page">
    <HelmetSEO
        title={"BloomWiz Software Private Limited"}
        description="Embark on a transformative journey with us as we craft cutting-edge
		software solutions that propel your business to unparalleled heights of
		success."
        image={caseStudyImage}
      />
      <header className="case-study-header-2">
        <div className="case-title-section">
          <h2>
            Empowering Business Growth Through Innovative Mobile Applications
          </h2>
        </div>
      </header>

      <section className="content-section">
        <div className="content-container">
          <div className="title-desc">
            <h3>
              In today's digital age, mobile applications have become
              indispensable tools for businesses to connect with their
              customers, expand their reach, and drive growth. Bloomwiz has
              successfully delivered exceptional mobile app solutions that have
              transformed our clients' businesses.
            </h3>
          </div>
          <section className="additional-content">
            <h3>Understanding Client Needs</h3>
            <p>
              Our approach begins with a deep understanding of our clients'
              business objectives, target audience, and market dynamics. By
              closely collaborating with clients, we identify opportunities to
              leverage mobile technology to create innovative solutions that
              deliver tangible results.
            </p>
          </section>
          <section className="additional-content">
            <h3>Designing User-Centric Experiences</h3>
            <p>
              We believe that a successful mobile app is built on a strong
              foundation of user experience (UX). Our design process focuses on
              creating intuitive and engaging interfaces that delight users. By
              conducting thorough user research and testing, we ensure that the
              app meets the needs and expectations of the target audience.
            </p>
          </section>
          <section className="additional-content">
            <h3>Developing Cutting-Edge Applications</h3>
            <p>
              Our team of skilled developers utilizes the latest technologies
              and industry best practices to build robust and scalable mobile
              applications. We focus on delivering high-performance apps that
              work seamlessly across different platforms and devices.
            </p>
          </section>

          <section className="framework-selection">
            <h2>Driving Business Growth</h2>
            <p>
              Our mobile app solutions have generated significant business
              growth for our clients by:
            </p>
            <div className="framework-list">
              <div className="framework-item">
                <h4>Expanding Market Reach:</h4>
                <p>
                  Enabling businesses to reach new customers and expand their
                  geographic footprint.
                </p>
              </div>
              <div className="framework-item">
                <h4>Enhancing Customer Engagement</h4>
                <p>
                  Providing a direct channel for communication and interaction
                  with customers.
                </p>
              </div>
              <div className="framework-item">
                <h4>Boosting Sales and Revenue</h4>
                <p>
                  Creating mobile commerce platforms to drive sales and increase
                  revenue.
                </p>
              </div>
              <div className="framework-item">
                <h4>Improving Operational Efficiency</h4>
                <p>
                  Streamlining business processes and enhancing productivity
                  through mobile apps.
                </p>
              </div>
              <div className="framework-item">
                <h4>Building Brand Loyalty</h4>
                <p>
                  Delivering exceptional user experiences to foster customer
                  loyalty and advocacy.
                </p>
              </div>
            </div>
          </section>

          <section className="additional-content">
            <h3>Conclusion</h3>
            <p>
              Bloomwiz is committed to helping businesses thrive in the
              mobile-first world. By combining our expertise in mobile app
              development with a deep understanding of user needs, we deliver
              innovative solutions that drive business growth and success.
            </p>
            <p>
              Would you like to delve deeper into a specific aspect of this case
              study?
            </p>
          </section>
        </div>
      </section>
    </div>
  );
};

export default CaseStudyDetailB;
