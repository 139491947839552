import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HeroSection from "./components/hero-section";
import About from "./components/about";
import Philosophy from "./components/philosophy";
import OurServices from "./components/our-services";
import CaseStudies from "./components/case-studies";
import Testimonials from "./components/testimonials";

import SoftwareDevelopmentDetail from "./components/services/software";
import Layout from "./components/layout";
import MobileAppDevelopmentDetail from "./components/services/mobile-app";
import WebDevelopmentDetail from "./components/services/web-app";
import CloudDevelopmentDetail from "./components/services/cloud-service";
import DevopsDevlopmentDetail from "./components/services/devops";
import CaseStudyDetailA from "./components/case-studies/case-study-1";
import CaseStudyDetailB from "./components/case-studies/case-study-2";
import CaseStudyDetailC from "./components/case-studies/case-study-3";
import CaseStudyDetailD from "./components/case-studies/case-study-4";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Layout>
                <HeroSection />
                <About />
                <Philosophy />
                <OurServices />
                <CaseStudies />
                <Testimonials />
              </Layout>
            </>
          }
        />
        <Route
          path="/services/software"
          element={
            <Layout>
              <SoftwareDevelopmentDetail />
            </Layout>
          }
        />
        <Route
          path="/services/mobile-app"
          element={
            <Layout>
              <MobileAppDevelopmentDetail />
            </Layout>
          }
        />
        <Route
          path="/services/web-app"
          element={
            <Layout>
              <WebDevelopmentDetail />
            </Layout>
          }
        />
        <Route
          path="/services/cloud-service"
          element={
            <Layout>
              <CloudDevelopmentDetail />
            </Layout>
          }
        />
        <Route
          path="/services/devops"
          element={
            <Layout>
              <DevopsDevlopmentDetail />
            </Layout>
          }
        />
        <Route
          path="/case-studies/case-study-1"
          element={
            <Layout>
              <CaseStudyDetailA />
            </Layout>
          }
        />
        <Route
          path="/case-studies/case-study-2"
          element={
            <Layout>
              <CaseStudyDetailB />
            </Layout>
          }
        />
        <Route
          path="/case-studies/case-study-3"
          element={
            <Layout>
              <CaseStudyDetailC />
            </Layout>
          }
        />
        <Route
          path="/case-studies/case-study-4"
          element={
            <Layout>
              <CaseStudyDetailD />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
