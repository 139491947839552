import React, { useEffect } from "react";

import mobileDetailVector from "../../Files/svg/mobile-detail-vector.svg";
import flutterIcon from "../../Files/svg/icons8-flutter.svg";
import cIcon from "../../Files/svg/icons8-c++.svg";
import reactIcon from "../../Files/svg/icons8-react-native.svg";
import "../../styles/serivces/mobile-app.css";
import HelmetSEO from "../helmet";

const MobileAppDevelopmentDetail = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="background-color">
      <HelmetSEO/>
      <div className="service-detail-container">
        <header className="service-header">
          <h1>Unleashing Business Potential</h1>
          <img
            src={mobileDetailVector}
            alt="Vector Description"
            className="vector-icon"
          />
        </header>

        <section className="service-description">
          <p>
            In today's dynamic mobile landscape, custom mobile applications
            serve as powerful differentiators, empowering businesses to elevate
            their brand presence, enhance operational efficiency, and cultivate
            deeper customer relationships. At Bloomwiz, we leverage our
            expertise in cross-platform development frameworks like Flutter,
            .NET MAUI, and React Native to deliver high-performance,
            feature-rich mobile applications tailored to your unique business
            objectives.
          </p>
        </section>
        <section className="additional-content">
          <h3>Collaborative Needs Assessment: Defining Success Together</h3>
          <p>
            Our collaborative approach underpins the success of every project.
            Through comprehensive needs assessments and interactive workshops,
            we work closely with you to establish a clear understanding of your
            target audience, desired user experience (UX), and the core
            functionalities that will propel your business forward. This
            in-depth analysis ensures that the resulting application seamlessly
            aligns with your overall business strategy, delivering a solution
            that transcends mere technical specifications and serves as a
            strategic asset.
          </p>
        </section>

        <section className="framework-selection">
          <h2>
            Framework Selection: A Strategic Choice Tailored to Your Project
          </h2>
          <div className="framework-list">
            <div className="framework-item">
              <h3>Flutter</h3>
              <p>
                Developed by Google, Flutter empowers us to craft visually
                stunning, native-looking applications for both iOS and Android
                platforms. Utilizing the Dart programming language, Flutter
                enables real-time code visualization through hot reload
                functionality, significantly accelerating development
                lifecycles. This framework excels in projects demanding
                exceptional visual fidelity and peak performance.
              </p>
              <img
                src={flutterIcon}
                alt="Flutter Icon"
                className="framework-icon"
              />
            </div>
            <div className="framework-item">
              <h3>.NET MAUI</h3>
              <p>
                Backed by Microsoft, .NET MAUI leverages the familiar C#
                language and XAML for user interface design. This framework
                shines in scenarios where a single codebase needs to be deployed
                across multiple platforms (Android, iOS, macOS, and Windows). By
                maximizing development efficiency and minimizing resource
                allocation, .NET MAUI is ideal for projects seeking a
                streamlined approach to multi-platform development.
              </p>
              <img
                src={cIcon}
                alt=".NET MAUI Icon"
                className="framework-icon"
              />
            </div>
            <div className="framework-item">
              <h3>React Native</h3>
              <p>
                Created by Facebook, React Native utilizes JavaScript, a widely
                popular language that facilitates the assembly of development
                teams with ease. Additionally, it boasts a large and active
                community with extensive libraries, enabling the rapid
                development of high-quality applications for both iOS and
                Android platforms.
              </p>
              <img
                src={reactIcon}
                alt="React Native Icon"
                className="framework-icon"
              />
            </div>
          </div>
        </section>

        <div className="timeline">
          <div>
            <h2>
              A Meticulous Development Process: Ensuring Quality and Security
            </h2>
            <p>
              Once the optimal framework is selected, our meticulous development
              process commences:
            </p>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">Design and Prototyping:</h3>
              <p className="timeline-description">
                Our skilled UX/UI designers meticulously craft user interfaces
                that prioritize user engagement and intuitive navigation,
                ensuring an exceptional user experience that fosters brand
                loyalty
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">
                Agile Development and Rigorous Testing:
              </h3>
              <p className="timeline-description">
                Our experienced developers leverage agile methodologies to build
                your application iteratively, ensuring continuous feedback and
                refinement. This approach, coupled with rigorous testing
                protocols, guarantees a flawless final product that adheres to
                the highest quality and security standards.
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3 className="timeline-title">
                Seamless Deployment and Ongoing Support:
              </h3>
              <p className="timeline-description">
                We provide comprehensive support throughout the application
                lifecycle, assisting with app store launches (Google Play Store
                and Apple App Store) and offering ongoing maintenance and
                support plans. This ensures your application remains secure,
                up-to-date, and continues to deliver optimal performance.
              </p>
            </div>
          </div>
        </div>

        <section className="framework-selection">
          <h2>
            The Power of Custom Mobile App Development: A Strategic Investment
          </h2>
          <div className="framework-list">
            <div className="framework-item">
              <h4>Tailored Functionality</h4>
              <p>
                Gain a solution specifically designed to address your unique
                business needs, leading to enhanced efficiency, streamlined
                operations, and exceptional user experiences.
              </p>
            </div>
            <div className="framework-item">
              <h4>Elevated Brand Awareness and Visibility</h4>
              <p>
                Mobile applications solidify your brand presence, increasing
                your visibility and facilitating deeper engagement with your
                target audience.
              </p>
            </div>
            <div className="framework-item">
              <h4>Improved Customer Relationships</h4>
              <p>
                Mobile apps provide a convenient and personalized way for
                customers to interact with your business, fostering loyalty and
                building lasting customer relationships.
              </p>
            </div>
            <div className="framework-item">
              <h4>Data-Driven Marketing</h4>
              <p>
                Apps collect valuable user data, empowering you to personalize
                marketing efforts, gain deeper customer insights, and optimize
                your overall marketing strategy.
              </p>
            </div>
          </div>
        </section>

        <section className="additional-content">
          <h3>Your Trusted Partner in Mobile App Development</h3>
          <p>
            By partnering with Bloomwiz for your custom mobile app development
            project, you gain more than just an app; you gain a dedicated and
            experienced development team. We guide you through every step of the
            process, ensuring a smooth and successful journey from
            conceptualization to reality. Let us help you unlock the
            transformative potential of custom mobile app development.
          </p>
          <p>Contact us today for a free consultation!</p>
        </section>
      </div>
    </div>
  );
};

export default MobileAppDevelopmentDetail;
